* {
    box-sizing: border-box;
    /* background-color: #0C0C0D; */
    background-size: cover;
    background-position: center;
}


body {
    font-family: "Roboto", sans-serif !important;
    margin: 0;
    padding: 0;
    height: 100% !important;
    width: 100% !important;
    background-color: #0C0C0D;
}

.trade_select .css-1nmdiq5-menu {
    /* background-color: rgba(255, 255, 255, 0.06) !important;
    backdrop-filter: blur(32px) !important; */
    backface-visibility: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 32px !important;
    height: auto;
}

.react-stockcharts-tooltip-hover {
    display: none;
}

.sidebarComp {

    transition: width 0.3s;
    width: 95px;

}

.sidebarComp:hover {
    width: 320px;
}


* {
    --toastify-color-light: #fff;
    --toastify-color-dark: #17181A !important;
    --toastify-color-info: transparent !important;
    --toastify-color-success: transparent !important;
    --toastify-color-warning: transparent !important;
    --toastify-color-error: transparent !important;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 80px !important;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: 'inter' !important;
    --toastify-z-index: 9999;
    --toastify-border-radius: 16px !important;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    --toastify-color-progress-light: linear-gradient(to right,
            #4cd964,
            #5ac8fa,
            #007aff,
            #34aadc,
            #5856d6,
            #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast {
    border-radius: 16px !important;
    padding: 1rem !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: 'inter' !important;
    color:  White !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
}
.Toastify__progress-bar ,
.Toastify__progress-bar--animated ,
.Toastify__progress-bar-theme--dark ,
.Toastify__progress-bar--default{
  display: none !important;
}