:root { 
--black_900_7f:#0000007f; 
--white_A700_7f:#ffffff7f; 
--teal_A400_33:#00f9a933; 
--cyan_A200:#00ffff; 
--cyan_A200_66:#00ffff66; 
--red_A200_7f:#ff54547f; 
--white_A700_33:#ffffff33; 
--white_A700_99:#ffffff99; 
--white_A700_cc:#ffffffcc; 
--teal_300:#50af95; 
--black_900_66:#00000066; 
--black_900:#000000; 
--black_900_66_01:#0b0b0b66; 
--white_A700_19:#ffffff19; 
--teal_A400_19:#00f9a819; 
--red_A200_01:#ff3750; 
--white_A700_e5:#ffffffe5; 
--red_A200_33_01:#ff375033; 
--cyan_A200_19:#00ffff19; 
--white_A700_0a:#ffffff0a; 
--red_A200:#ff5454; 
--gray_900:#131836; 
--black_900_0f:#0000000f; 
--white_A700_0f:#ffffff0f; 
--cyan_A200_33:#00ffff33; 
--teal_A400:#00f9a9; 
--teal_A400_7f:#00f9a87f; 
--white_A700_66:#ffffff66; 
--red_A200_19:#ff545419; 
--white_A700_07:#ffffff07; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--red_A200_33:#ff545433; 
}