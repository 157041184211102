* {
  box-sizing: border-box;
  /* background-color: #0C0C0D; */
  background-size: cover;
  background-position: center;
}


body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100% !important;
  background-color: #0C0C0D;
}


.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}

.common-pointer {
  cursor: pointer;
}

.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}

.dhiwise-navigation h1 {
  color: white;
}

.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}

.dhiwise-navigation ul {
  padding: 0;
}


.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.dhiwise-navigation a {
  color: #132cdc;
}

table {
  border-spacing: 0;
  width: 100%;
}

.common-select {
  z-index: 10000;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.drawable-icon {

  position: absolute;
  margin: auto;
  z-index: 1000;
}

.input-wrap {
  position: relative;
}

option {
  color: #000;
}

.table-wrap {
  overflow: auto;
}

input:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}


.mobile-menu {
  display: none;
  cursor: pointer;
}

.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .mobile-menu {
    display: block;
  }

  .header-row {
    width: 100% !important;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .common-row-list>ul {

    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}

.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.menu-overlay {

  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}

.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.common-row-list>ul {

  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list>ul {

  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li {

  width: 100%;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}

.buy_sell_tab {
  background-color: transparent;
  cursor: pointer;
  flex: 1;
  font-weight: bold;
  height: 100%;
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  padding: 18px 0 18px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);

  /* backdrop-filter: blur(32px); */
}

.react-tabs__tab--selected.buy_sell_tab {
  color: white;
}

.your_trade {

  color: rgba(255, 255, 255, 0.60);
  font-size: 14px !important;
  font-weight: 700 !important;
  stroke: #2ED3B7 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  width: 160px;
  height: 48px;
  padding: 12px 16px;
  backdrop-filter: blur(4px);
}

.react-tabs__tab--selected.your_trade {

  color: #2ED3B7 !important;
  font-weight: 700 !important;
  fill: #2ED3B7 !important;
  stroke: #2ED3B7 !important;
  border-bottom: 1px solid #2ED3B7 !important;
}


.limit_market {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400 !important;
}

.react-tabs__tab--selected.limit_market {
  color: rgba(255, 255, 255, 1);
  font-weight: 700 !important;

}

/* #react-select-5-listbox,#react-select-7-listbox,#react-select-3-listbox{
  opacity: 1;
  z-index: 0;
  backdrop-filter: blur(32px) !important;
  background-color: rgba(255, 255, 255, 0.06) !important;
} */
.trade_select {
  background: #1D1E20 !important;
  border-radius: 32px;
  border: none !important;
  outline: none !important;
  height: 64px !important;
  /* backdrop-filter: blur(32px); */
}

.trade_select .css-15lsz6c-indicatorContainer {
  background: #1D1E20 !important;
  margin-right: 20px !important;
}

.trade_select .css-1xc3v61-indicatorContainer {
  background: #1D1E20 !important;
  margin-right: 20px !important;

}

.trade_select .css-13cymwt-control,
.trade_select .css-t3ipsp-control,
.trade_select .css-1dimb5e-singleValue {
  background: #1D1E20 !important;
  border: none !important;
  font-family: 'DM Sans';
  box-shadow: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #FFFFFF;
  outline: none !important;
  display: flex;
  align-items: center;
  width: 100% !important;
  border-radius: 32px;
  /* backdrop-filter:blur(32px) !important; */

}

.trade_select .css-319lph-ValueContainer,
.trade_select .css-1s2u09g-control,
.trade_select .css-1pahdxg-control {
  /* background-color: rgba(255, 255, 255, 0.06) !important; */
  /* border-radius: 32px 32px 32px 32px !important; */
  cursor: pointer !important;
}

.trade_select .css-1u9des2-indicatorSeparator {
  background: #1D1E20 !important;
}




.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.border_grey_gradient {
  position: relative;
  display: inline-block;
}



.border_grey_gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 2px;
  /*control the border thickness */
  background: linear-gradient(180deg, #504f4f, #2e2e2e);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.red_gradient {
  position: relative;
  display: inline-block;
}



.red_gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(90deg, #FF5555, #FF3750);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.border-gradient {
  position: relative;
  display: inline-block;
}



.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(90deg, #00F9A9, #00FFFF);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.border-gradient_header {
  position: relative;
  display: inline-block;
}



.border-gradient_header::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 40px;
  padding: 1px;
  /*control the border thickness */
  background: linear-gradient(90deg, #00F9A9, #00FFFF);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}



.graph {
  width: 100% !important;
  height: 100% !important;
}

.canvasjs-chart-canvas {
  border-radius: 16px !important;
  /* background-color: #000000 !important ;  */
  border-color: black !important;
  color: white !important;
  /* backdrop-filter: blur(64px) !important; */
}

.canvasjs-chart-canvas {
  width: 100%;
}

.canvasjs-chart-toolbar {
  display: none !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.border_popup {
  position: relative;
  display: inline-block;
}



.border_popup::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 2px;
  /*control the border thickness */
  background: linear-gradient(180deg, #504f4f, #2e2e2e) !important;
  -webkit-mask:
    linear-gradient(#504f4f, #2e2e2e) content-box,
    linear-gradient(#504f4f, #2e2e2e);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  /* backdrop-filter: blur(40px) !important; */
}

.canvasjs-input-field {
  border: none !important;
  border-color: transparent !important;
}

.buy-button {
  border: none;
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #00f9a9 100%);
  border-radius: 1rem;
  padding: 1px;
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24),
    0px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
}

.buy-button-inner {
  background: linear-gradient(135deg, #222529 0%, #15181c 100%);
  border-radius: 1rem;
}

.button-text {
  font-size: 1rem;
  background: linear-gradient(334deg, #0ff 0%, #00f9a9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* @media only screen and (min-width: 1900px) and (max-width: 1000000px) {
  .css-1wvake5 {
    width: 500px !important;
  }
} */
.canvasjs-navigator-panel {
  margin-left: 0px !important;
}
.react-tabs__tab--selected.Tab_container {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 2px solid white !important;
  outline: none !important;
  font-weight: 700;
  color:white !important;
}
.selected-tab {
  border-bottom: 2px solid   white; 
  border-radius: var(--spacing-0, 0rem); /* Change the border radius value as needed */
  /* Other styles for the selected tab */
}
.connect_bg_container {
  background: var(--color-white-25, rgba(255, 255, 255, 0.06));
 
}